import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import Div100vh from 'react-div-100vh';

import Layout from '../components/Layout/Layout';
import Carousel from '../components/Carousel/Carousel';

import { useIndexPageData, useSiteMetadata } from '../hooks';
import { initIntersectionObserver } from '../utils/intersectionObserver';

export interface IndexPageProps {
	props: any;
}

const IndexPage = (props: IndexPageProps) => {
	const { name } = useSiteMetadata();
	const projects = useIndexPageData();

	useEffect(() => {
		initIntersectionObserver();
		return () => initIntersectionObserver();
	}, []);

	return (
		<Layout>
			<Helmet title={`Work | ${name}`} />
			<Div100vh className='p-index'>
				<Carousel items={projects} />
				<div className='o-carousel__progress'>
					<p className='o-carousel__progress-text'>1</p>
					<p className='o-carousel__progress-text'>{projects.length}</p>
				</div>
			</Div100vh>
		</Layout>
	);
};

export default IndexPage;
