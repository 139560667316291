import Scrollbar from 'smooth-scrollbar';

export default class HorizontalScrollPlugin extends Scrollbar.ScrollbarPlugin {
	static pluginName = 'horizontalScroll';

	transformDelta(delta: any, fromEvent: any) {
		if (!/wheel/.test(fromEvent.type)) {
			return delta;
		}

		const { x, y } = delta;

		return {
			y: 0,
			x: Math.abs(x) > Math.abs(y) ? x : y,
		};
	}
}
