import React, { ReactElement } from 'react';

import TransitionLink from '../TransitionLink/TransitionLink';
import ProjectCoverAsset from '../ProjectCoverAsset/ProjectCoverAsset';

import { IProject } from '../../types/index.types';
import { isNil } from 'ramda';

export interface ProjectProps {
	project: IProject;
}

const Project: React.FC<ProjectProps> = ({ project }): ReactElement | null => {
	const { title, slug, coverImage } = project;

	const renderCarousel = (): ReactElement => (
		<TransitionLink cn='m-project__link' to={`work/${slug}`}>
			<article className='m-project'>
				<h3 className='m-project__title u-heading-medium' data-inview data-animatein='project'>{title}</h3>
				<div className='m-project__asset'>
					<div className='content'>
						<ProjectCoverAsset mobile={coverImage.mobile} desktop={coverImage.desktop} />
					</div>
				</div>
			</article>
		</TransitionLink>
	);

	return !isNil(coverImage) ? renderCarousel() : null;
};

export default Project;
