import React, { ReactElement, useEffect, useRef } from 'react';
import Scrollbar from 'smooth-scrollbar';
import OverscrollPlugin from 'smooth-scrollbar/plugins/overscroll';

import { isMobile } from '../../utils/detectMobile';
import { IProjectNode } from '../../types/index.types';
import HorizontalScrollPlugin from '../../classes/HorizontalScrollPlugin';

import Project from '../Project/Project';
import { animateCarousel } from './Carousel.animations';

export interface CarouselProps {
	items: IProjectNode[];
}

const Carousel: React.FC<CarouselProps> = ({ items }): ReactElement => {
	const carousel = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (carousel && carousel.current) { initCarouselScroll(carousel.current); }
		return () => {
			if (carousel && carousel.current) { initCarouselScroll(carousel.current); }
		};
	}, [carousel]);

	useEffect(() => {
		if (carousel && carousel.current) { animateCarousel(carousel.current); }
	}, []);

	const initCarouselScroll = ($el: HTMLElement): void => {
		Scrollbar.use(HorizontalScrollPlugin, OverscrollPlugin);
		Scrollbar.init($el, {
			damping: isMobile() ? 0.01 : 0.05,
			renderByPixels: true,
			alwaysShowTracks: true
		});
	};

	return (
		<section className='o-carousel' ref={carousel}>
			{items.map((p: IProjectNode, i: number): ReactElement => (
				<div className='o-carousel__item' key={`${p.node.id}-${i}`}>
					<Project project={p.node} />
				</div>
			))}
		</section>
	);
};

export default Carousel;
