import gsap from 'gsap';

import { helpersAnimation } from '../../utils/helpersAnimation';

export const animateCarousel = (item: HTMLElement | Element): void => {
	const $progress = item.querySelector('.scrollbar-track-x');
	const $numbers = document.querySelector('.o-carousel__progress');

	gsap.fromTo(item,
		{
			x: '100vw',
			opacity: '0',
		},
		{
			x: '0',
			opacity: '1',
			duration: 2,
			ease: helpersAnimation.ease,
		});

	gsap.fromTo($progress,
		{
			opacity: '0',
		},
		{
			opacity: '1',
			duration: helpersAnimation.duration,
			delay: 2,
			ease: helpersAnimation.ease,
		});

	gsap.fromTo($numbers,
		{
			opacity: '0',
		},
		{
			opacity: '1',
			duration: helpersAnimation.duration,
			delay: 2.3,
			ease: helpersAnimation.ease,
		});
};
